/* eslint-disable no-extra-boolean-cast */
import React, { useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useQuery } from 'react-query';
import { fetchOpenWeatherApi } from 'lib/api';

import styled from 'styled-components';

import { CONGESTION_LEVELS } from 'lib/constants';
import { COLORS } from 'lib/theme';
import { Button } from 'react-bootstrap';
import { AiOutlinePoweroff } from 'react-icons/ai';
import lbaLogo3 from 'assets/images/lbaLogo3.png';

import { useAuth } from '../lib/contexts/auth';

const InformationBoardComponent = styled.div`
  float: right;
  width: 20%;
  height: 100%;
  padding: 1rem;
  background-color: ${COLORS.transparentWhite10};
  border-radius: 5px;

  & div.container {
    height: 100%;
    width: 100%;
    position: relative;
    & div.title {
      color: ${COLORS.light};
      font-size: 26px;
      font-weight: bold;
      margin-bottom: 20px;
      text-align: center;
    }
  }

  & div.weather {
    background-color: ${COLORS.transparentWhite};
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    padding: 1rem;
    margin-bottom: 15px;

    & div.descDiv {
      font-size: 24px;
      text-align: right;
      margin-right: 0.7rem;
    }
  }

  & div.legends {
    background-color: ${COLORS.transparentWhite};
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 1rem;
    line-height: 1.7;
  }

  & div.logo {
    width: 100%;
  }

  & i.owi {
    color: ${COLORS.primary};
  }

  & div.bottomDiv {
    display: inline-grid;
    margin-top: 0.7rem;
  }

  & div.buttonDiv {
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: right;
    width: 100%;
    padding-right: 1.5rem;
  }
`;

export default function InformationBoard() {
  const { logout } = useAuth();

  const [weatherInfo, setWeatherInfo] = useState({});

  const legends = useMemo(
    () => [
      CONGESTION_LEVELS.sparse,
      CONGESTION_LEVELS.normal,
      CONGESTION_LEVELS.crowded,
      CONGESTION_LEVELS.congested,
    ],
    []
  );

  const weatherQuery = useQuery({
    queryKey: 'openWeather',
    queryFn: fetchOpenWeatherApi,
    refetchInterval: 3600000, // refetch every hour
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (weatherQuery.data) {
      const { data } = weatherQuery;
      const {
        main: { temp },
        weather: [weather],
      } = data;
      setWeatherInfo({
        description: weather?.description,
        icon: weather?.icon,
        temperature: temp,
      });
    }
  }, [weatherQuery.data]);

  return (
    <InformationBoardComponent>
      <div className="container">
        <div className="title">
          {process.env.REACT_APP_NAME}
          <hr />
        </div>
        {weatherQuery.isSuccess && (
          <div className="weather">
            <i className={`owi owi-fw owi-4x owi-${weatherInfo.icon}`} />
            <div className="descDiv">
              <div>{weatherInfo.temperature}°C</div>
              <small>{weatherInfo.description}</small>
            </div>
          </div>
        )}
        <div className="legends">
          {legends.map((legend) => (
            <div key={legend.name}>
              <FontAwesomeIcon icon="circle" color={legend.fill} size="xs" />{' '}
              {legend.label}
            </div>
          ))}
        </div>

        <div className="bottomDiv">
          <div className="logo">
            <img src={lbaLogo3} style={{ width: '94%' }} alt="LBASense" />
          </div>
        </div>
        <div className="buttonDiv">
          <Button variant="outline-secondary" onClick={logout}>
            <small>Logout</small> <AiOutlinePoweroff size={18} />
          </Button>
        </div>
      </div>
    </InformationBoardComponent>
  );
}
