import { CONGESTION_LEVELS, SITE_NAME } from 'lib/constants';
import { Col, Row } from 'react-bootstrap';
import React, { useMemo } from 'react';
import { getCongestionLevel } from 'lib/utils';

import { COLORS } from 'lib/theme';
import Container from 'components/Container';
import GaugeChart from 'components/GaugeChart';
import InformationBoard from 'components/InformationBoard';
import { sensors } from 'lib/config';
import styled from 'styled-components';
import { useQueries } from 'react-query';

import { fetchRealtimeVisitorCount } from '../lib/api';
import SensorsMap from './SensorsMap';

const ChartsContainer = styled.div`
  position: absolute;
  width: 75%;

  & div.siteNameDiv {
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 1rem;
    color: ${COLORS.light};
  }

  & div.rowDiv {
    color: ${COLORS.light};
    display: grid;
    grid-template-columns: 40% 60%;
  }

  & div.mapDiv {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding-right: 0;

    & svg.sensors {
      position: absolute;
      top: 0;
      height: 100%;
      width: 49%;
    }

    & #icon {
      & path {
        fill: inherit;
      }
    }
  }

  & div.sensorInfoDiv {
    background-color: ${COLORS.transparentWhite10};
    margin-left: 0.5rem;

    padding: 1rem;
    border-radius: 5px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 28vh;
  }

  & div.chart {
    background-color: ${COLORS.transparentWhite10};
    border: 1px ${COLORS.light} solid;
    border-radius: 5px;
    margin: 0.3rem;
  }

  & div.gaugeChartDiv {
    height: 73%;
    margin-bottom: -1rem;
  }

  & div.name {
    color: ${COLORS.light};
    font-size: 16px;
    font-weight: bold;
    margin-top: 5px;
    position: relative;
    text-align: center;
    & small {
      position: absolute;
      right: 5px;
      font-size: 12px;
      font-weight: normal;
    }
  }
`;

export default function KioskMap() {
  // const { sensors } = useGlobal();
  const SENSORS = useMemo(
    () => Object.values(sensors).sort((a, b) => a.index - b.index),
    []
  );

  const results = useQueries(
    SENSORS.map((sensor) => ({
      queryKey: ['sensor', sensor.id],
      queryFn: fetchRealtimeVisitorCount,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }))
  );

  const resultData = useMemo(
    () =>
      results.map((result, i) => {
        const sensor = SENSORS[i];
        const data = result?.data?.data || {};
        const count = data?.count || 0;
        const max = sensor.area / CONGESTION_LEVELS.congested.distance;
        const level = getCongestionLevel(count, sensor.area);

        return {
          id: sensor.id,
          name: sensor.name,
          area: sensor.area,
          lat: sensor.lat,
          lng: sensor.lng,
          count,
          level,
          max,
        };
      }),
    [results]
  );

  return (
    <Container>
      <ChartsContainer>
        <div className="siteNameDiv">{SITE_NAME}</div>
        <Row className="rowDiv">
          <Col className="mapDiv">
            <SensorsMap />
          </Col>
          <Col className="sensorInfoDiv">
            {resultData.map((data) => {
              const { id, area, name, count, level, max } = data;
              return (
                <div key={id + name} className="chart">
                  <div className="name">
                    {name}
                    <small>
                      {area} m<sup>2</sup>
                    </small>
                  </div>
                  <div className="gaugeChartDiv">
                    <GaugeChart
                      data={
                        count
                          ? [
                              {
                                title: name,
                                value: count > max ? max : count,
                                color: level.fill,
                              },
                            ]
                          : [
                              {
                                title: name,
                                value: 0,
                              },
                            ]
                      }
                      options={{
                        label: () => level.label,
                        labelPosition: 0,
                        labelStyle: {
                          fontSize: '12px',
                          fontFamily: 'sans-serif',
                          fill: level.fill,
                        },
                        totalValue: max,
                      }}
                      label={{
                        fill: level.fill,
                        value: level.label,
                      }}
                    />
                  </div>
                  <div className="text-center pt-0">
                    Total:{' '}
                    <span style={{ fontWeight: 'bold' }}>{data.count}</span>
                  </div>
                </div>
              );
            })}
          </Col>
        </Row>
      </ChartsContainer>
      <InformationBoard />
    </Container>
  );
}
