export const LBA_API = {
  realtimeVisitorCount: 'api-dev/sap-api/realtime-visitor-count/',
  login: 'api-master/apis/master/Login/',
  sensorHealth: 'api-master/api/Hub/Management/SensorHealth',
};

export const CONGESTION_LEVELS = {
  sparse: {
    // label: '쾌적',
    label: 'Sparse',
    name: 'sparse',
    fill: '#36B1E4',
    distance: 5, // social distance per person in square meters
  },
  normal: {
    // label: '적정',
    label: 'Normal',
    name: 'normal',
    fill: '#00EA00',
    distance: 4, // social distance per person in square meters
  },
  crowded: {
    // label: '다소 혼잡',
    label: 'Crowded',
    name: 'crowded',
    fill: 'gold',
    distance: 3, // social distance per person in square meters
  },
  congested: {
    // label: '매우 혼잡',
    label: 'Congested',
    name: 'congested',
    fill: 'red',
    distance: 2, // social distance per person in square meters
  },
};

export const CAROUSEL = {
  itemsPerSlide: 6,
  interval: 6000,
};

export const MAIN_SENSORS = [3332, 3268, 3655, 3585, 3298];

export const SCREEN = {
  height: process.env.REACT_APP_SCREEN_HEIGHT,
  width: process.env.REACT_APP_SCREEN_WIDTH,
};

export const SITE_NAME = '하동군 마을 교통 안전 (HADONG VILLAGES)';
