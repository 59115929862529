/* eslint-disable arrow-body-style */
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useQuery } from 'react-query';

import { getErrorMessage } from '../utils';
import { useAuth } from './auth';
import { fetchSensorHealth } from '../api';

const GlobalContext = createContext({
  sensors: {},
});

export const useGlobal = () => useContext(GlobalContext);
export const GlobalConsumer = GlobalContext.Consumer;

export default function GlobalProvider({ children }) {
  const { authenticated } = useAuth();

  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(false);
  const [sensors, setSensors] = useState(null);

  const sensorListQuery = useQuery({
    queryKey: 'sensorList',
    queryFn: fetchSensorHealth,
    enabled: authenticated,
    refetchOnWindowFocus: false,
    onError: (error) => {
      setErrorMessage(getErrorMessage(error));
    },
    onSuccess: ({ data }) => {
      setSensors(data);
    },
  });

  useEffect(async () => {
    Promise.all([sensorListQuery]).finally(() => setLoading(false));
  }, []);

  const contextValues = useMemo(
    () => ({ loading, errorMessage, sensors, sensorListQuery }),
    [loading, errorMessage, sensors]
  );

  return (
    <GlobalContext.Provider value={contextValues}>
      {children}
    </GlobalContext.Provider>
  );
}
