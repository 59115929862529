import * as yup from 'yup';

import { Eye, EyeSlash } from 'react-bootstrap-icons';
import { Alert, Button, FloatingLabel, Form } from 'react-bootstrap';
import React, { useEffect } from 'react';

import styled from 'styled-components';
import { SCREEN } from 'lib/constants';
import { Redirect, useHistory } from 'react-router-dom';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import bg from 'assets/images/bg.png';
import lbaLogo1 from 'assets/images/lbaLogo1.png';
import chroma from 'chroma-js';

import { useFormik } from 'formik';
import { useAuth } from '../lib/contexts/auth';

import { COLORS } from '../lib/theme';
import Loader from '../components/Loader';

const LoginComponent = styled.div`
  height: ${SCREEN.height}vh;
  width: ${SCREEN.width}vw;
  padding-top: 30vh;
  overflow: hidden;
      background: ${COLORS.light};

  background-position: 'center';
  background-size: 'cover';
  background-repeat: 'no-repeat';
  background-attachment: 'fixed;
`;

export default function Login() {
  const { authenticated, login, errorMessage, loading } = useAuth();
  const [showPassword, setShowPassword] = React.useState(false);

  const { errors, handleBlur, handleChange, handleSubmit, touched, values } =
    useFormik({
      initialValues: {
        username: '',
        password: '',
      },
      validationSchema: yup.object({
        username: yup.string().required(),
        password: yup.string().required(),
      }),
      onSubmit: async (val) => {
        const { username, password } = val;
        login({ username, password });
      },
    });

  if (authenticated) {
    return <Redirect to="/kiosk" />;
  }
  if (loading) {
    return <Loader />;
  }

  return (
    <LoginComponent>
      <Row className="justify-content-center">
        <Col md={5} sm="12" className="login">
          <div
            style={{
              background: chroma(COLORS.dark).alpha(0.85),
              padding: '2rem',
              borderRadius: '0.3rem',
              paddingTop: '1rem',
            }}>
            <h1 className="text-white">
              <div
                style={{
                  justifyContent: 'space-between',
                  display: 'inline-flex',
                }}>
                <Col style={{ alignSelf: 'center' }}>
                  LBASense{' '}
                  <span className="mt-1" style={{ fontSize: 12 }}>
                    v{process.env.REACT_APP_VERSION}
                  </span>
                </Col>
                <Col style={{ textAlign: 'right' }}>
                  <img src={lbaLogo1} style={{ width: '70%' }} alt="LBASense" />
                </Col>
              </div>
            </h1>
            <hr className="text-white" />
            <div className="mb-4 text-white">Login to your account</div>
            <Form className="align-center" onSubmit={handleSubmit}>
              <FloatingLabel
                className="mb-3"
                controlId="username"
                label="Username">
                <Form.Control
                  name="username"
                  placeholder="Enter username"
                  value={values.username}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.username && errors.username}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="password"
                label="Password"
                className="mb-3">
                <Form.Control
                  placeholder="Password"
                  aria-label="Password"
                  type={showPassword ? 'text' : 'password'}
                  name="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={touched.password && errors.password}
                />
                {!errors.password && (
                  <div className="position-absolute top-50 end-0 translate-middle p-1">
                    {showPassword ? (
                      <Eye
                        className="cursor-pointer"
                        size={20}
                        onClick={() => {
                          setShowPassword(false);
                        }}
                      />
                    ) : (
                      <EyeSlash
                        className="cursor-pointer"
                        size={20}
                        onClick={() => {
                          setShowPassword(true);
                        }}
                      />
                    )}
                  </div>
                )}
              </FloatingLabel>
              {!!errorMessage && <Alert variant="danger">{errorMessage}</Alert>}
              <div className="d-grid pt-4">
                <Button type="submit" variant="light">
                  Login
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </LoginComponent>
  );
}
