import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import GlobalProvider from '../lib/contexts/global';
import { useAuth } from '../lib/contexts/auth';

export default function PrivateRoute({ component: Component }) {
  const { authenticated } = useAuth();

  return (
    <Route
      render={(props) =>
        authenticated ? (
          <GlobalProvider>
            <Component {...props} />
          </GlobalProvider>
        ) : (
          <Redirect
            to={{ pathName: '/login', state: { from: props.location } }}
          />
        )
      }
    />
  );
}
