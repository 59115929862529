import React from 'react';
import { HashRouter, Redirect, Route, Switch } from 'react-router-dom';
import KioskMap from 'pages/KioskMap';
import Loader from 'components/Loader';
import Login from 'pages/Login';
import PrivateRoute from './components/PrivateRoute';
import CarouselProvider from './lib/contexts/carousel';

import { useAuth } from './lib/contexts/auth';

export default function App() {
  const { authenticated } = useAuth();
  return (
    <CarouselProvider>
      <HashRouter>
        <React.Suspense fallback={<Loader />}>
          <Switch>
            <Route
              exact
              path="/login"
              name="Login"
              render={(props) => <Login {...props} />}
            />
            <PrivateRoute path="/kiosk" component={KioskMap} />
            {authenticated ? (
              <Redirect to="/kiosk" />
            ) : (
              <Redirect from="*" to="/login" />
            )}
          </Switch>
        </React.Suspense>
      </HashRouter>
    </CarouselProvider>
  );
}
