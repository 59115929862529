import React, { createContext, useContext, useMemo, useState } from 'react';
import { CAROUSEL } from '../constants';

const CarouselContext = createContext({
  interval: CAROUSEL.interval,
  stopCarousel: () => {},
  startCarousel: () => {},
});

export const useCarousel = () => useContext(CarouselContext);

export const CarouselConsumer = CarouselContext.Consumer;

export default function CarouselProvider({ children }) {
  const [interval, setInterval] = useState(CAROUSEL.interval);

  const stopCarousel = () => {
    setInterval(null);
  };

  const startCarousel = () => {
    setInterval(CAROUSEL.interval);
  };

  const contextValues = useMemo(
    () => ({ interval, stopCarousel, startCarousel }),
    [interval]
  );

  return (
    <CarouselContext.Provider value={contextValues}>
      {children}
    </CarouselContext.Provider>
  );
}
