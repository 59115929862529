import styled from 'styled-components';

import { SCREEN } from 'lib/constants';
import { COLORS } from 'lib/theme';
import chroma from 'chroma-js';

export default styled.div`
  background-color: ${chroma(COLORS.dark).darken(0.65)};
  padding: 1rem 2rem 1rem 2rem;
  height: ${SCREEN.height}vh;
  width: ${SCREEN.width}vw;
  overflow: hidden;
  position: relative;
`;
