import { CONGESTION_LEVELS } from 'lib/constants';
import React, { useMemo, useState, useEffect } from 'react';
import { getCongestionLevel, getMapCenter } from 'lib/utils';

import { sensors } from 'lib/config';
import { useQueries } from 'react-query';
import chroma from 'chroma-js';
import {
  MapContainer,
  TileLayer,
  Marker,
  Tooltip,
  Circle,
} from 'react-leaflet';
import markerIconPng from 'leaflet/dist/images/marker-icon.png';
import L, { Icon } from 'leaflet';
import { BsFillCircleFill } from 'react-icons/bs';

import { fetchRealtimeVisitorCount } from '../lib/api';

export default function SensorsMap() {
  const [bounds, setBounds] = useState([]);
  const SENSORS = useMemo(
    () => Object.values(sensors).sort((a, b) => a.index - b.index),
    []
  );

  const results = useQueries(
    SENSORS.map((sensor) => ({
      queryKey: ['sensor', sensor.id],
      queryFn: fetchRealtimeVisitorCount,
      refetchInterval: 60000,
      refetchIntervalInBackground: true,
      refetchOnWindowFocus: false,
    }))
  );

  const resultData = useMemo(
    () =>
      results.map((result, i) => {
        const sensor = SENSORS[i];
        const data = result?.data?.data || {};
        const count = data?.count || 0;
        const max = sensor.area / CONGESTION_LEVELS.congested.distance;
        const level = getCongestionLevel(count, sensor.area);

        return {
          id: sensor.id,
          name: sensor.name,
          area: sensor.area,
          lat: sensor.lat,
          lng: sensor.lng,
          count,
          level,
          max,
        };
      }),
    [results]
  );
  const mapCenter = getMapCenter(resultData);

  useEffect(() => {
    const b = resultData
      .filter((sensor) => sensor.lat && sensor.lng)
      .map((sensor) => [sensor.lat, sensor.lng]);
    setBounds(b);
  }, []);
  return (
    <MapContainer
      style={{ height: '100%', width: '100%', borderRadius: '4px' }}
      center={mapCenter}
      bounds={bounds}
      zoom={11}
      className="map"
      whenCreated={(map) => {
        map.flyToBounds(bounds, { duration: 1 });
        map.fitBounds(bounds, {
          animate: false,
          paddingTopLeft: [100, 100],
          paddingBottomRight: [100, 100],
        });
      }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      {resultData.map((sensor) => {
        const { id, name, count, level, lat, lng, area } = sensor;

        return (
          <Marker
            id={id}
            key={id}
            position={[lat, lng]}
            opacity={8}
            zIndexOffset={10000}
            // icon={L.divIcon({
            //   className: 'w-auto',
            // })}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [17, 27],
              })
            }>
            <Circle
              stroke={false}
              center={[lat, lng]}
              pathOptions={{
                fillColor: chroma(level.fill).darken(2.2),
              }}
              radius={area * 20}
            />

            <Tooltip
              direction="top"
              offset={[0, -10]}
              opacity={0.8}
              permanent
              style={{ padding: '0.2rem', borderRadius: '5px' }}>
              <div
                style={{
                  minWidth: '100px',
                }}>
                <BsFillCircleFill
                  color={level.fill}
                  style={{ marginRight: '0.5rem' }}
                />
                <span style={{ fontWeight: 'bolder' }}>
                  {name}: {count}
                </span>
              </div>
            </Tooltip>
          </Marker>
        );
      })}
    </MapContainer>
  );
}
