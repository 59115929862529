export const sensors = {
  3332: {
    id: 3332,
    area: 50,
    name: 'Sensor 3332',
    index: 0,
    lat: 35.13976451126372,
    lng: 127.80592809981162,
  },
  3268: {
    id: 3268,
    area: 50,
    name: 'Sensor 3268',
    index: 1,
    lat: 35.23025806347853,
    lng: 127.73417094391453,
  },
  3655: {
    id: 3655,
    area: 50,
    name: 'Sensor 3655',
    index: 2,
    lat: 35.196218369884974,
    lng: 127.7860421377772,
  },
  3585: {
    id: 3585,
    area: 50,
    name: 'Sensor 3585',
    index: 3,
    lat: 35.15827083547149,
    lng: 127.79789058592482,
  },
  3298: {
    id: 3298,
    area: 50,
    name: 'Sensor 3298',
    index: 4,
    lat: 35.22669346356457,
    lng: 127.73647169312281,
  },
};
